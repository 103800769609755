import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/js/all";
import "controllers";
import "stylesheets/application";
import "swiper/swiper-bundle.min.css"

Rails.start()
ActiveStorage.start()
Turbolinks.start()

// hotfix for Safari NSURLSession WebSocket bug
const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments)
  }
}