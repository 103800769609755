import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// let myModal = null;

export default class extends Controller {
  static targets = [ "agencyField" ];
  connect () {
    if (this.agencyFieldTarget) {
      new TomSelect(this.agencyFieldTarget, {
        plugins: {
          remove_button:{
            title:"Remove this item",
          }
        },
        maxItems: 3,
        create: function(input, _callback) {
          document.getElementById("agency-new").style.display = "block";
        }
      });
    }
  }

}